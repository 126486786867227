html {
  scroll-behavior: smooth;
}

.primaryHeader {
  z-index: 5;
  display: grid;
  place-items: center;
  font-family: "Montserrat";
  position: absolute;
  transform: translate(-50%, -50%);
  top: 4rem;
  left: 50%;
  background: transparent;
  width: 95%;
}

.navWrapper {
  padding: 1em 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.14);
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(50, 94, 63, 1);
  backdrop-filter: blur(5px);
  border-radius: 1.25rem;
}

.mobileNavToggle {
  display: none;
}

.navList {
  font-size: 16px;
}

.navList {
  display: flex;
  gap: clamp(1rem, 5vw, 3rem);
  font-weight: var(--fw-semi-bold);
}

.navList {
  color: white;
}

.navList :hover,
.navList :focus {
  color: black;
}

.logo {
  display: flex;
  align-items: center;
  color: white;
  gap: 10px;
}

.button {
  display: inline-flex;
  background: #27b67a;
  border-radius: 90px;
  color: #061113;
}

.display-sm-hidden {
  display: none;
}
.icon-close,
.icon-hamburger {
  color: white;
  z-index: 10;
}
.toggleDisplay {
  display: none;
}

.navbarOptionbtns {
  display: flex;
  gap: 20px;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}

.navbarbtn {
  background: transparent;
  color: #fefefe;
  font-family: "Poppins";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
}

.highlightedOption {
  font-weight: 600;
}

.navbarbtn:hover {
  color: #27b67a;
}

.highlighted a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: default;
  letter-spacing: 0.1em;

  color: #27b67a;
}

.QuotaBtn {
  background: #27b67a;
  border-radius: 90px;
  padding: 10px 30px;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}

.headerTitle {
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height, or 110% */

  letter-spacing: 0.1em;
}

.menuIcon {
  display: none;
}

@media (min-width: 50em) {
  .display-md-inline-flex {
    display: inline-flex;
  }
}
@media (max-width: 50em) {
  /* .primaryHeader {
    padding-top: 0;
    top: 0;
  } */
  .mobileWidth {
    width: 100% !important;
  }
  /* .navWrapper {
    background: linear-gradient(180deg, #041422 -19.53%, #150b1d 122.66%);
    border-radius: 0;
    padding: 2em 1em;
  } */

  .primaryNavigation {
    display: none;
    position: fixed;
    padding: 4em;
    inset: 1rem 0 auto;
    max-width: 15rem;
    margin-left: auto;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 6;
  }

  .primaryHeader[data-overlay]::before {
    content: "";
    position: fixed;
    inset: 0;
    z-index: 5;
    /* height: 100vh; */
    background-image: linear-gradient(rgb(0 0 0 / 0.3), rgb(0 0 0 / 0.8));
  }

  .navList {
    display: grid;
    gap: var(--size-600);
    text-align: center;
    font-weight: var(--fw-bold);
  }

  .primaryNavigation[data-visible] {
    display: block;
  }
  .toggleDisplay[data-visible] {
    display: block;
  }

  .mobileNavToggle {
    z-index: 10;
    display: block;
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0.5em;
  }

  .logo img {
    transform: scale(0.8);
  }
}

@media only screen and (min-width: 800px) {
  .navbarOptionbtns {
    display: flex !important;
  }
}

@media only screen and (max-width: 800px) {
  .headerTitle {
    font-size: 15px;
    line-height: 15px;
  }

  .QuotaBtn {
    display: block;
  }
  .menuIcon {
    display: inline;
    width: 20px;
    height: 20px;
  }
  /* .navWrapper {
    border: none;
  } */

  .navbarOptionbtns {
    width: 50%;
    position: absolute;
    top: 90px;
    right: 0;
    padding: 100px 20px;
    text-align: center;
    background: #150b1d;
  }

  .navbarbtn {
    line-height: 40px;
  }
}

@media (max-width: 580px) {
  .navWrapper {
    padding: 1em 2em;
  }
}

@media (max-width: 400px) {
  .navWrapper {
    padding: 1em 1em;
  }
  .navbarOptionbtns {
    width: 70%;
    position: absolute;
    top: 90px;
    right: 0;
    padding: 100px 20px;
    text-align: center;
    background: #150b1d;
  }
}
.getaquotaBtn {
  background: #27b67a;
  border-radius: 0.5rem;
  padding: 10px 30px;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  text-align: center;
  transition: 300ms background-position ease-in-out;
  width: fit-content;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.getaquotaBtn:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #27b67a;
  border-radius: 10px;
  z-index: -2;
}
.getaquotaBtn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s;
  border-radius: 10px;
  z-index: -1;
}
.getaquotaBtn:hover {
  color: #000;
}
.getaquotaBtn:hover:before {
  width: 100%;
}

@media (max-width: 800px) {
  .getaquotaBtn {
    padding: 2px 20px;
    font-size: 12px;
    line-height: 20px;
    margin: 10px auto;
  }
}
