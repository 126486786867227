.App {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  scrollbar-width: thin;
  scrollbar-color: #00d186 #2b2b2b;
  scroll-behavior: smooth;
}

.App::-webkit-scrollbar {
  width: 10px;
}

.App::-webkit-scrollbar-track {
  background: #2b2b2b;
}

.App::-webkit-scrollbar-thumb {
  background: #00d186;
  border-radius: 3px;
}

.circleBg {
  background-image: var(--circle-bg);
  background-size: 100%;
}

.greyMountainWrapper {
  background-image: url("/src/assets/grey-mountains.png");
  background-size: cover;
}

.lineBgWrapper {
  background-image: var(--secondary-bg);
  background-size: cover;
}
