.borderText {

    color: #27B67A;
  }


  .partners_name{
    color: #fff;
    text-align: center;
    font-size: 20px;

  }


  .ourpartner_heading{
    font-size: 52px;
  }


  .ourClientReviewHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 78px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 2em;
    margin-bottom: 0.5em;
}

.ourClientReviewHeading span{
    background: linear-gradient(90.06deg, #27B67A 1.07%, #38FFCF 99.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.ourClientReview{
    width: 100%;
}


.carouselWrapper{
    width: 100%;
}
.clientReviewCrousel{
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    animation: slide 16s infinite;
}
@keyframes slide {
    0% {
        transform: translateX(100%);
    }
    25% {
        transform: translateX(100%);
    }
    30% {
        transform: translateX(0%);
    }
    55% {
        transform: translateX(0%);
    }
    60% {
        transform: translateX(-100%);
    }
    85% {
        transform: translateX(-100%);
    }
    92%{
        transform: translateX(0%);
    }
    100% {
        transform: translateX(100%);
    }
}
.clientReviewCrouselItem{
    background: rgba(67, 67, 67, 0.19);
    border-radius: 15px;
    min-width: 80%;
    padding: 50px 100px;    
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    gap: 25px;
    margin-top: 50px;
}



.clientName{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    color: #15D28E;
}

.clientInfo{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.7);
}

.reviews_outer{
  width: 70%;
  margin: auto;
}

@media(max-width:800px){
  .reviews_outer{
    width: 90%;
    margin: auto;
  }
}


.clientImgIcon{
  width: 120px;
  height: auto;
  border-radius: 50%;
  margin-bottom: 20px;
}

.company_name{
  color: #15D28E;
}

.starDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.clientWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
}

.quotesIcon{
    position: absolute;
      left: 8%;
      width: 40px !important;

  
}


  @media(max-width:850px){
    .ourpartner_heading{
      font-size: 30px;
    }
    .clientReviewCrouselItem{
    
      padding: 50px 30px;    
    
  }
  }
  
  .primaryText {
    color: var(--primary-font-color);
  }
  
  .secondaryText {
    color: var(--secondary-font-color);
  }
  
  .card {
    background-color: var(--card-bg-transparent);
  }
  
  .image {
    filter: var(--img-filter-light);
  }
  
  .card:nth-child(2) {
    position: relative;
    top: -70px;
  }
  
  @media screen and (max-width: 1240px) {
    .card:nth-child(2) {
      position: relative;
      top: 0px;
    }

  }
  

  @media(max-width:500px){
    .partners_name{
      font-size: 14px;
    }
  }