.landing {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: var(--landing-bg);
}

.invertButton {
  color: var(--primary-font-color) !important;
}

.landingText {
  color: var(--primary-font-color);
}

#coin_image{
  animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 25px); }
  to   { transform: translate(0, -0px); }    
}

/* @keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .earth {
    animation: spin infinite 20s linear;
    overflow: hidden;
  }
} */

@media screen and (max-width: 900px) {
  .landing {
    height: 100%;
  }
}
