.newsletter {
  max-width: 2200px;
  margin: auto;
}

.container {
  padding-top: 40px;
  padding-bottom: 112px;
  font-family: 'Poppins';
  font-style: normal;
  background: url('./assets/ball.png'), #110d1e;
  background-repeat: no-repeat;
  background-position: center;
}

.emailError {
  color: red !important;
  font-size: 1em !important;
  position: absolute;
  bottom: -4em;
}

.logo {
  margin-left: 4em;
  height: 2.5em;
}

.box {
  border-radius: 20px;
  width: 70%;
  margin: 7em auto;
  display: flex;
  flex-direction: column;
  gap: 4em;
  align-items: center;
  position: relative;
}

.box > div {
  height: 4em;
  display: flex;
  align-items: center;
  width: 100%;
}

.box > p > span {
  color: #27b67a;
}

.box input {
  background-color: #1f1f1f;
  border: none;
  border-radius: 0.625em 0 0 0.625em;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.25em;
  color: rgba(255, 255, 255, 0.7);
  padding: 1.188em;
  outline: none;
  width: 80%;
  height: 100%;
}

.box > p {
  font-weight: 700;
  font-size: 3em;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
}

.box button {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.875em;
  text-align: center;
  color: #ffffff !important;
  cursor: pointer;
  background: #27b67a;
  border-radius: 0 1.5em 1.5em 0;
  border: none;
  align-self: center;
  width: 20%;
  height: 100%;
}

@media screen and (min-width: 1600px) {
  .container {
    font-size: 18px;
  }
}

@media screen and (max-width: 1400px) {
  .container {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    font-size: 14px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    font-size: 10px;
    padding-bottom: 56px;
    background: #110d1e;
  }
  .box {
    width: 80%;
  }
  .box > div {
    flex-direction: column;
    gap: 2em;
    height: auto;
  }
  .box input {
    border-radius: 0.6em;
    width: 100%;
    font-size: 1.5em;
    box-sizing: border-box;
  }
  .box button {
    border-radius: 0.6em;
    padding: 0.8em;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .container {
    font-size: 8px;
  }
  .logo {
    margin: auto;
  }
}
