.securityMain {
  padding: 4rem 0 6rem 0;
}

.securityConentWrapper {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.securityContent2,
.securityContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
}

.leftContent1,
.rightContent2 {
  width: 60%;
}

.rightContent1,
.leftContent2 {
  width: 40%;
}

.securityHeading1,
.securityHeading2 {
  color: #fff;
  font-family: var(--lh-clash);
  font-size: 3.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.securityDesc,
.securityDesc1 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: rgba(255, 255, 255, 0.7);
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1.81rem;
}

.content1Img {
  width: 100%;
  height: 33rem;
  border-radius: 3.5rem;
}

.content1Img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.leftContent2 {
  position: relative;
}

.contetn2Img_1 {
  width: 23.7775rem;
  height: 30rem;
  border-radius: 2.9025rem;
}
.content2Img_1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2.9025rem;
}

.contetn2Img_2 {
  position: relative;
  z-index: 1;
  margin-top: -10rem;
  margin-left: 6rem;
  width: 23rem;
  height: 33rem;
  border-radius: 28rem;
}
.contetn2Img_2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 28rem;
}

.securityDesc2 {
  margin-top: 4.81rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: #15d28e;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 1024px) {
  .securityContent2 {
    flex-direction: column;
  }

  .rightContent2 {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .securityHeading1,
  .securityHeading2 {
    font-size: 35px;
  }
  .leftContent1 {
    width: 100%;
  }
  .leftContent2 {
    display: none;
  }
  .securityContent {
    flex-direction: column;
  }

  .rightContent1 {
    width: 80%;
  }

  .securityDesc,
  .securityDesc1 {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 550px) {
  .securityHeading1,
  .securityHeading2 {
    font-size: 30px;
  }

  .securityDesc2 {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 425px) {
  .rightContent1 {
    width: 100%;
    height: 20rem;
  }

  .content1Img {
    height: 20rem;
  }
}
