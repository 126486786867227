.mainFooter{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #030405 0%, #120C19 100%);
    padding: 4% 8% 8% 8%;
}
.title{
    display: flex;
    gap: 10px;
    align-items: center;
    color: white;
}

.socialIconDiv{
    display: flex;
    gap: 10px;
}

.socialIconDiv img{
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.signupWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: center;
}

.signupInput{
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 10px
}

.signupInput img{
    background: #27B67A;
    width: 15px;
    height: 15px;
}

.signupInput input{
    height: 100%;
    border: none;
    height: 47px;
    background: #1F1F1F;
    color: white;
    padding: 16px;
    line-height: 10px;
    font-size: 15px;
}

.signupInput button{
    border: none;
    padding: 16px 14px;
    background: #27B67A;
}

.signupTxt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.highlighted{
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #27B67A;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    padding-bottom: 5px;
    padding-top: 5px;
}

.webpageLinks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
}

.desc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: rgba(255, 255, 255, 0.74);
}

.title p{
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.1em;
}

.titleDesc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: rgba(255, 255, 255, 0.71);
}

.socials1{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.socials2{
    display: none;
    flex-direction: column;
    gap: 10px;
}

.footerLogoDiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.webpageLinksWrapper{
    width: 20%;
    display: flex;
    justify-content: space-between;
}


@media only screen and (max-width: 800px) {
    .mainFooter{
        flex-direction: column;
        align-items: center;
        gap: 2em;
    }
    .titleDesc{
        text-align: center;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1em;
        width: 90%;
        margin-top: 10px;
    }
    .webpageLinksWrapper{
        display: flex;
        gap: 1em;
        width: 80%;
        justify-content: space-evenly;
    }
    .socials1{
        display: none;
    }
    .socials2{
        display: flex;
    }
    .socialIconDiv img{
        cursor: pointer;
        width: 15px;
        height: 15px;
    }
}