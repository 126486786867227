.howWork {
  margin-top: 10rem;
}

.howWorkHeading {
  color: var(--fc-white);
  text-align: center;
  font-family: var(--fc-clashVar);
  font-size: 2.75rem;
  font-weight: 600;
}

.workWrapper {
  width: 90%;
  margin: auto;
  display: grid;
  margin-top: 3.75rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 4.38rem;
}

.workContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.workItem {
  display: flex;
  align-items: center;
  padding: 2rem 2.5rem;
  gap: 1.5rem;
  border-radius: 1.25rem;
  border: 1px solid var(--fc-grayStroke);
  background: var(--fc-blackBackground);
}

.workContentHeading {
  color: var(--fc-white);
  font-family: var(--fc-clash);
  font-size: 1.5rem;
  font-weight: 500;
}

.workIcon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workContent {
  width: 100%;
}

.workConentDesc {
  color: var(--fc-textDesc);
  font-family: var(--fc-outFit);
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: -0.0225rem;
}

.workImage img {
  margin: auto;
}

@media (max-width: 1024px) {
  .workItem {
    padding: 1.5rem 1.2rem;
  }
}

@media (max-width: 888px) {
  .howWorkHeading {
    font-size: 2.25rem;
  }
  .workWrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 569px) {
  .howWorkHeading {
    font-size: 2rem;
  }

  .workContentHeading {
    font-size: 1.15rem;
  }

  .workConentDesc {
    font-size: 1rem;
  }
}

@media (max-width: 488px) {
  .howWork {
    margin-top: 6rem;
  }
}
