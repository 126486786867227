.chat-box {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #282828;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: auto;
  overflow: hidden;
  z-index: 9999;
  padding: 20px;
}

.chat-box.visible {
  display: block;
}

.chat-box .title {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: x-large;
}

.title>img{
  cursor: pointer;
}

.chat-box input[type="text"] {
  width: 100%;
  outline: none;
  border: none;
  caret-color: white;
  background: #1d1d28;
  color: white;
  padding: 0px 10px;
}

.inputboxdiv {
  display: flex;
  align-items: center;
  background: #1d1d28;
  padding: 10px 0px;
  border-radius: 10px;
}

.sendButton {
  background: #1d1d28;
  outline: none;
  border: none;
  color: white !important;
  padding: 0px 10px;
}

.Textbody {
  width: 100%;
  height: 45vh;
  overflow-y: auto;
}

.Textbody {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.Textbody::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.TextbodyLeft {
  width: 300px;
  display: flex;
  justify-content: flex-start;
  margin-right: 30px;
}

.TextbodyLeft > div {
  width: auto;
  background: #28b67a;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 15px;
  padding: 10px;
  margin: 15px 0px;
}

.TextbodyRight {
  width: 300px;
  display: flex;
  justify-content: end;
  margin-left: 30px;
}

.TextbodyRight > div {
  width: auto;
  background: white;
  color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 5px;
  padding: 10px;
}

@media only screen and (max-width: 550px) {
  .chat-box {
    right: 10px;
    bottom: 10px;
  }
  .Textbody {
    width: 100%;
    height: 55vh;
    overflow-y: auto;
  }
  .TextbodyLeft {
    width: 250px;
  }
  .TextbodyRight {
    width: 250px;
  }
}
