.borderText {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--border-font-stroke);
  color: var(--border-font-fill);
}

.primaryText {
  color: var(--primary-font-color);
}

.secondaryText {
  color: var(--secondary-font-color);
}

.card {
  background-color: var(--card-bg-transparent);
}

.lightning {
  opacity: 0.8;
}


.whyusimage{
  animation: rotate 12s infinite ease-in-out;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}